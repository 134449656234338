.container-programas {
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrapper-cards {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    .title-card-programas {
      font-size: 30px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
