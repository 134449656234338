.container-card {
  width: 85%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-background;
  border: 1px solid #b9bcc5;
  border-radius: 20px;
  padding: 20px 50px;
  cursor: default;
  gap: 40px;
  .content-img-card {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13px;
    .titulo-uno-card {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
    .subtitulo-card {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .content-text-card {
    width: 65%;
    .titulo-dos-card {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 15px;
    }
    .wrapper-items-card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;
      .item-card {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .imgCardCon {
    width: 115px;
  }
  .imgCardPro {
    width: 180px;
  }
  .container-card {
    padding: 20px 15px;
    flex-direction: column;
    height: inherit;
    row-gap: 20px;
    .content-img-card {
      flex-direction: column-reverse;
      width: 100%;
    }

    .content-text-card {
      width: 90%;
   
      .wrapper-items-card {
        width: 100%; 
        padding-left: 0 !important;
        .item-card {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
}
