.container-header-inicio {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/Web/Fondo_SuperiorHome.png);
  background-repeat: no-repeat;
  background-size: cover;
  .wrapper-header-inicio {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20vh;
    .section-header-inicio-text {
      width: 50%;
      .main-title {
        color: $color-text-light;
        font-size: 45px;
        font-weight: 900;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 20px;
      }
      .header-inicio-text {
        color: $color-text-light;
        font-size: 22px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 615px;
      }
    }
    .section-header-inicio-img {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      #img-header-inicio {
        width: 100%;
        max-width: 600px;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .container-header-inicio {
    height: 90vh;
    background-image: url(../../assets/images/Mobile/Fondo_SuperiorHome.png);
    .wrapper-header-inicio {
      flex-direction: column;
      margin-top: unset;

      .section-header-inicio-text {
        width: 100%;
        .main-title {
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
        .header-inicio-text {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .section-header-inicio-img {
        width: 100%;
        #img-header-inicio {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }
}
