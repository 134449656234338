.container-header-plataforma {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/Web/Fondo_SuperiorPlataforma.png);
  background-repeat: no-repeat;
  background-size: cover;
  .wrapper-header-plataforma {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    .section-header-plataforma-text {
      width: 50%;
      margin-top: -20px;
      .proximamente{
        display: block;
        border-radius: 15px;
        width: 100px;
        background-color: #f3f4f6;
        color: #182a60;
        padding: 7px 0px;
        font-weight: bold;
        font-size: 12px;
        height: 18px;
        padding: 0;
        text-align: center;
        cursor: default;
      }
      .main-title-plataforma {
        cursor: default;
        color: $color-text-light;
        font-size: 30px;
        font-weight: 900;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 20px;
      }
      .header-plataforma-text {
        color: $color-text-light;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        .text-decoration-plataforma {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          width: 100%;
          max-width: 680px;
        }
      }
      .container-btn-plataforma {
        margin-top: 20px;
        display: flex;
        gap: 15px;
        .btn-crear-cuenta {
          width: 180px;
          padding: 10px 15px;
          border-radius: 20px;
          color: $color-text;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
          cursor: pointer;
        }
        .btn-ingresar {
          width: 180px;
          padding: 10px 15px;
          border-radius: 20px;
          border: 1px solid white;
          background-color: transparent;
          color: $color-text-light;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
          cursor: pointer;
        }
      }
    }
    .section-header-plataforma-img {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      #img-header-plataforma {
        width: 100%;
        max-width: 600px;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .container-header-plataforma {
    background-image: url(../../assets/images/Mobile/Fondo_Plataforma.png);
    background-color: #F3F4F6;
    height: 110vh;
    max-height: 850px;
    align-items: start;
    padding-top: 60px;
    .wrapper-header-plataforma {
      flex-direction: column;
      gap: 25px;
      margin-top: unset;
      .section-header-plataforma-text {
        width: 100%;
        margin-top: unset;

        .main-title-plataforma {
          font-size: 30px;
          font-weight: 800;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
        }
        .header-plataforma-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          .text-decoration-plataforma {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
        .container-btn-plataforma {
          // justify-content: center;
          .btn-crear-cuenta {
            width: 154px;
          }
          .btn-ingresar {
            width: 154px;
          }
        }
      }
      .section-header-plataforma-img {
        #img-header-plataforma {
          width: 300px;
        }
      }
    }
  }
}
