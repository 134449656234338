@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

@import './styles/base/typography';
@import './styles/base/reset';
@import './styles/base/global';
@import './styles/abstracts/variables';

// componentes

@import './styles/components/navBar';
@import './styles/components/headerInicio';
@import './styles/components/sectionQueHacemos';
@import './styles/components/sectionMapa';
@import './styles/components/sectionAlianzas';
@import './styles/components/sectionQuienesSomos';
@import './styles/components/footer';
@import './styles/components/card';
@import './styles/pages/consultora';
@import './styles/pages/construccion';
@import './styles/components/headerConsultora';
@import './styles/pages/programas';
@import './styles/components/headerProgramas';
@import './styles/components/citacion';
@import './styles/components/headerPlataforma';
@import './styles/components/agendaReunion';
@import './styles/components/footerPlataforma';

a {
    text-decoration: none !important;
    
}
