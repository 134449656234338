.container-reunion {
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper-reunion {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .seccion-img-agenda {
      .img-agenda {
      }
    }
    .seccion-text-agenda {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 100%;
      max-width: 650px;
      .title-agenda {
        font-size: 30px;
        font-weight: 900;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        cursor: default;

      }
      .text-agenda {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        cursor: default;

      }
      .btn-agenda-reunion {
        width: 180px;
        padding: 10px 15px;
        border-radius: 20px;
        color: $color-text-light;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        cursor: pointer;
        background-color: $color-primary;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .container-reunion {
    background-color: $color-background;
    padding-bottom: 60px;
    .wrapper-reunion {
      flex-direction: column-reverse;
      .seccion-img-agenda {
        .img-agenda {
          width: 100%;
        }
      }
      .seccion-text-agenda {
        align-items: center;
        .title-agenda {
          font-size: 25px;
          font-weight: 900;
          line-height: 30px;
          cursor: default;

        }
        .text-agenda {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          cursor: default;

        }
      }
    }
  }
}
