.testimonials {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  /* background-image: url("../../assets/images/Web/Fondo_MitadHome.png"); */
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center;
  height: 110vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 70px;
  padding-top: 50px;
}
.sectionTitle {
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  display: block;
  margin-top: 20px;
  margin-bottom: -10px;
  cursor: default;
}

.shadow-effect {
    padding: 20px;
    padding-top: 70px;

    padding-bottom: 10px;
    /* width: 40vw;
     min-width: 260px;
    max-width: 600px;   */
    text-align: center;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
    border-radius: 20px;
    border: 1px solid #B9BCC5;
    background-color: #F3F4F6;
    height: fit-content;
    min-height: 215px;
    position: relative;
  }
  #shadow-effect p {
    color: #101820;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
  }
  .img-circle {
    border-radius: 50%;
    max-width: 115px;
    min-height: 115px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
    position: absolute;
    top: -54px;
    margin-left: 50%;
    transform: translateX(-68%);
  }
  .contImg {
    display: flex;
    column-gap: 10px;
    position: absolute;
    top: -54px;
    margin-left: 50%;
    transform: translateX(-58%);
  }
  .img-circle-doble {
    border-radius: 50%;
    max-width: 115px;
    min-height: 115px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
    
  }

  .titleT {
    color: #182A60;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
 
 
  #customer-testimonoals .item {
    text-align: center;
    padding: 20px;
    padding-top: 60px;
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
    transform: translateX(-20px);
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #475EA4;
    transform: translate3d(0px, -50%, 0px) scale(0.5);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    background: #fff;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.5);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 



  /* Slider infinito */

  .slider {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }

  .slider .slide-track {
    display: flex;
    column-gap: 25px;
    animation: scroll 40s linear infinite;
    width: calc(120px * 25);
  }

  .slider .slide {
    width: 120px;
  }
  .slider .slide  img{
    width: 120px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0)
    }
    100% {
      transform: translateX(calc(-120px * 20))
    }
  }
  
  @media all and (max-width: 991px) {
    .slider {
      width: 100%;
    }
    .testimonials {
      height: fit-content;
    }
    .sectionTitle {
      margin-top: 40px;
    }
    .contImg {
      margin-left: 0;
      left: 0;
      width: 100%;
      transform: unset;
    }
    

    @keyframes scroll {
      0% {
        transform: translateX(0)
      }
      100% {
        transform: translateX(calc(-120px * 30))
      }
    }
  }