.container-nav {
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  z-index: 1;
  .wrapper-nav {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav {
      display: flex;
      align-items: center;
      gap: 50px;
      list-style: none;
      text-decoration: none;
      font-size: 17px;
      .item-nav {
        color: $color-text-light;
        font-weight: 500;
      }
      .active {
        font-weight: 700;
      }
    }
  }
}

@media all and (max-width: 576px) {
  .container-nav {
    position: relative;
    .wrapper-nav {
      .container-icons-menu {
        .icon-menu {
          color: white;
          font-size: 30px;
        }
      }
      .nav {
        position: absolute;
        background-color: $color-text-light;
        flex-direction: column;
        right: 0;
        top: 90px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 90vh;
        justify-content: center;
        .item-nav {
          color: #292929;
          width: 90%;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;

          .list-item {
            text-align: center;
            border-bottom: 1px solid #dfdfdf;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}
