.container-footer {
  background-color: #F3F4F6 ;
  background-image: url(../../assets/images/Web/Footer.png);
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper-footer {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 60px;
    .section-azul {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .content-text-footer {
        display: flex;
        flex-direction: column;
        gap: 25px;
        .title-footer {
          font-size: 35px;
          font-weight: 900;
          line-height: 42px;
          letter-spacing: 0em;
          text-align: left;
          color: $color-text-light;
          cursor: default;
        }
        .text-footer {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: $color-text-light;
          width: 100%;
          max-width: 615px;
          cursor: default;
        }
        .btn-footer {
          width: 180px;
          padding: 10px 15px;
          border-radius: 20px;
          cursor: pointer;
          a {
            color: #182A60 !important;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
    .section-gris {
      padding-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: translateY(70px);
      .content-info-footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .logo-footer {
          color: #182a60;
          font-size: 40px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
        .text-info-footer {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          cursor: default;
        }
      }
      .content-menu-footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .text-menu-footer {
          font-size: 25px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: $color-text;
          cursor: default;
        }
        .content-nav-footer {
          display: flex;
          align-items: center;
          gap: 20px;
          .link-footer {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: $color-text-dark;
          }
        }
      }
      .content-sociales-footer {
        .img-sociales {
          width: 52px;
          height: 52px;
        }
      }
    }
  }
}


/* Estilos para el pie de YP */
.cont-yp {
  width: 100%;
  background-color: #2C4282;
  height: 29px;
  padding-top: 4px;

}

.text-yp {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  cursor: default;
}

.link-yp {
  text-decoration: none;
  color:  #FFF !important;
  cursor: pointer;
}





@media all and (max-width: 991px) {
  .container-footer {
    .wrapper-footer {
      height: 90vh;
      .section-azul {
        .content-img-footer {
          width: 250px;
          .img-pc-footer {
            width: 90%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .container-footer {
    background-image: url(../../assets/images/Mobile/Footer_mobile.png);
    position: relative;
    .wrapper-footer {
      justify-content: end;
      position: absolute;
      bottom: 0;
      .section-azul {
        flex-direction: column-reverse;
        .content-text-footer {
          align-items: center;
          .title-footer {
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
          }
          .text-footer {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .btn-footer {
            color: #182a60;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .content-img-footer {
          width: 100%;
          display: flex;
          justify-content: center;
          .img-pc-footer {
            width: 90%;
          }
        }
      }
      .section-gris {
        padding-top: 0px;
        padding-bottom: 5px;
        transform: unset;
        .content-info-footer {
          .logo-footer {
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
          }
          .text-info-footer {
            color: #101820;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .content-menu-footer {
          display: none;
        }
        .content-sociales-footer {
          .img-sociales {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}