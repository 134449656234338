.container-header-consultora {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/Web/Fondo_SuperiorConsultora.png);
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  height: fit-content;
  min-height: 80vh;
  gap: 50px;

  .wrapper-header-consultora {
    width: 90%;
    display: flex;
    justify-content: center;
    .section-header-consultora-text {
      width: 85%;
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      .main-title {
        color: $color-text-light;
        font-size: 30px;
        font-weight: 800;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
      }
      .header-consultora-text {
        color: $color-text-light;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        .text-decoration-consultora {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .container-header-consultora {
    background-image: url(../../assets/images/Mobile/Fondo_consultora.png);

    .wrapper-header-consultora {
      .section-header-consultora-text {
        align-items: flex-start;
        .header-consultora-text {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          .text-decoration-consultora {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
  }
}
