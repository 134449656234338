.container-citacion {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150px;

  .wrapper-citacion {
    width: 90%;
    display: flex;
    align-items: center;
    .seccion-citacion {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      position: relative;
      .text-citacion {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        cursor: default;
        width: 100%;
        max-width: 850px;
        .text-decoration-citacion {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
      .content-name-cargo {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        .name-citacion {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: $color-text;
        }
        .name-cargo-citacion {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: center;
          color: $color-text;
        }
      }
      .img-comillas-izq {
        position: absolute;
        bottom: 75px;
        left: 0;
      }
      .img-comillas-der {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .seccion-img-citacion {
      width: 20%;
      .img-citacion {
        max-width: 300px;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .container-citacion {
    .wrapper-citacion {
      gap: 20px;
      flex-direction: column;
      .seccion-citacion {
        width: 100%;
        .text-citacion {
          text-align: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          .text-decoration-citacion {
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .img-comillas-izq {
          bottom: 40px;
          width: 60px;
        }
        .img-comillas-der {
          width: 60px;
          top: 28px;
        }
      }
      .seccion-img-citacion {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .img-citacion {
          width: 20%;
        }
        .content-name-cargo {
          display: flex;
          flex-direction: column;
          gap: 2px;
          .name-citacion {
            color: #182a60;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .name-cargo-citacion {
            color: #182a60;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
}
