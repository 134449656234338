.containerConstruccion {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .left {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    .imgLogo {
      width: 180px;
    }
    p{
      width: 70%;
      color: white;
      margin-top: 200px;
    }
  }
  .right {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .imgConstruccion {
      width: 500px;
    }
    .btnConstruccion{
      height: 34px;
      width: 190px;
      border-radius: 8px;
      outline: none;
      color: white;
      background-color: rgb(11, 11, 78);
      cursor: default;
    }
  }

}

@media screen and (max-width: 768px) {

  .containerConstruccion {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 40px;
    .left {
      width: 100%;
      height: 150px;
      flex-direction: row;
      justify-content: space-around;
      .imgLogo {
        width: 80px;
      }
      p{
        margin-top: 0px;
      }
    }
    .right {
      width: 100%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .imgConstruccion {
        width: 250px;
        margin-top: 40px;
        margin-bottom: 50px;
      }
      .btnConstruccion{
      }
    }
  
  }
}
