.container-quienes-somos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  .wrapper-quienes-somos {
    width: 90%;
    .title-quienes-somos {
      font-size: 30px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      color: $color-text;
      margin-bottom: 50px;
      cursor: default;
    }
    .section-quienes-somos {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 200px;
      .card-quienes-somos {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .img-quienes-somos {
          width: 250px;
          height: 250px;
          border-radius: 183.5px;
        }
        .text-quienes-somos {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color:"black";
          cursor: pointer;
          a{
            color:"black"
          }
        }
        .text-decoration-quienes-somos{
          cursor: default;
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .container-quienes-somos {
    .wrapper-quienes-somos {
      .title-quienes-somos {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 35px;
      }
      .section-quienes-somos {
        flex-direction: column;
        gap: 30px;
        .card-quienes-somos {
          .img-quienes-somos {
            width: 150px;
            height: 150px;
          }
          .text-quienes-somos {
            color: #182a60;
            text-align: center;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .text-decoration-quienes-somos {
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
